import {
  bootstrap,
  bulma,
  css3,
  django,
  express,
  html5,
  javascript,
  mongodb,
  nodejs,
  npm,
  postgresql,
  python,
  react,
  sass,
  typescript,
  yarn,
  vue,
  pinia,
  jira,
  figma,
  gitlab,
} from '../../../assets/TechStack';

export const TechStackData = [
  {
    key: 0,
    title: 'Bootstrap',
    src: bootstrap,
    alt: 'bootstrap',
  },
  {
    key: 1,
    title: 'Bulma',
    src: bulma,
    alt: 'bulma',
  },
  {
    key: 2,
    title: 'CSS3',
    src: css3,
    alt: 'css3',
  },
  {
    key: 3,
    title: 'Django',
    src: django,
    alt: 'django',
  },
  {
    key: 4,
    title: 'Express',
    src: express,
    alt: 'express',
  },
  {
    key: 5,
    title: 'HTML5',
    src: html5,
    alt: 'html5',
  },
  {
    key: 6,
    title: 'JavaScript',
    src: javascript,
    alt: 'javascript',
  },
  {
    key: 7,
    title: 'MongoDB',
    src: mongodb,
    alt: 'mongodb',
  },
  {
    key: 8,
    title: 'Node.js',
    src: nodejs,
    alt: 'Node.js',
  },
  {
    key: 9,
    title: 'NPM',
    src: npm,
    alt: 'npm',
  },
  {
    key: 10,
    title: 'PostgreSQL',
    src: postgresql,
    alt: 'postgresql',
  },
  {
    key: 11,
    title: 'Python',
    src: python,
    alt: 'python',
  },
  {
    key: 12,
    title: 'React',
    src: react,
    alt: 'react',
  },
  {
    key: 13,
    title: 'SASS',
    src: sass,
    alt: 'sass',
  },
  {
    key: 14,
    title: 'TypeScript',
    src: typescript,
    alt: 'typescript',
  },
  {
    key: 16,
    title: 'Vue',
    src: vue,
    alt: 'vue',
  },
  {
    key: 17,
    title: 'Pinia',
    src: pinia,
    alt: 'pinia',
  },
  {
    key: 18,
    title: 'Jira',
    src: jira,
    alt: 'jira',
  },
  {
    key: 19,
    title: 'Figma',
    src: figma,
    alt: 'figma',
  },
  {
    key: 20,
    title: 'GitLab',
    src: gitlab,
    alt: 'gitlab',
  },
];
